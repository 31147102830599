import SearchPanel from '@components/searchPanel'
import { Box, Button, ButtonGroup, Grid, IconButton, Popover, Typography } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'
import { DisposalGroundEnum, FormDrsListModel } from '@services/model/form/form.DRS.model'
import FormDrsService from '@services/formService/form.DRS.service'
import { NavigateTo } from '@utils/navigate'
import { FormStatusEnum, RightsCategory } from '@services/model/form/form.model'
import { KeyValPair } from '@models/Common'
import { RiFileTextFill } from 'react-icons/ri'
import { RiEdit2Fill } from 'react-icons/ri'
import useAPIFetch from '@hooks/useAPIFetch'
import useLocalStorage from '@hooks/useLocalStorage'
import { DocumentExporter } from '@utils/documentExporter'
import { BsFiletypeDocx, BsFiletypePdf } from 'react-icons/bs'
import { GetImageDimensions } from '@utils/image'
import _ from 'lodash'
import AddBoxIcon from '@mui/icons-material/AddBox'
import cleanString from '@utils/cleanString'
const DailyRecordSummaryAllRecords = (props: { showMyRecord?: boolean }) => {
  const allRecordsList = useRef<FormDrsListModel[]>([])
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionsRequest } = useAPIFetch()
  const [currentContract, setCurrentContractId] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )
  const refMounted = useRef(true)
  const { state: globalState } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )
  const [contractId, setContractId] = useState<number>(0)
  useEffect(() => {
    setContractId(currentContract.contractId)
  }, [currentContract])
  const { t } = useTranslation()
  const { hasRightByCatAndCode } = useContext(GlobalContext)

  const [extraListInfo, setExtraListInfo] = useState<{
    submittedByList: string[]
    approvedByList: string[]
    formIdList: KeyValPair[]
    parentFormIdList: KeyValPair[]
  }>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    parentFormIdList: [],
  })

  const [drsOptions, setDrsOptions] = useState<any>({})
  useEffect(() => {
    setOptionsRequest({
      callback: async () => {
        const list: Array<any> = []
        FormDrsService.GetDrsGeneralOptions().then((res) => {
          setDrsOptions(res)
        })
      },
    })
  }, [currentContract])

  const handleClickDownload = async (
    formId: string,
    formStatus: string,
    reportNo: string,
    type?: 'docx' | 'pdf',
  ) => {
    let records: any = []
    if (formId) {
      let res = await FormDrsService.GetDrsForm(formId, formStatus)
      if (res) {
        records.push(res)
      }
      if (type === 'pdf') {
        await exportDocx(records, 'pdf-download')
      } else {
        await exportDocx(records, 'blob-download')
      }
    }
  }

  const handleMultiDownload = async (type: 'docx' | 'pdf') => {
    let records: any = []
    let idList: any = []
    selected?.map((selectedRowNo: number) => {
      idList.push(allRecordsList.current?.[selectedRowNo]?.formId)
    })

    let res = await FormDrsService.GetDrsExportList({ idList: idList })
    if (res) {
      records = res.list
    }

    if (type === 'pdf') {
      await exportDocx(records, 'pdf-download')
    } else {
      await exportDocx(records, 'blob-download')
    }
  }

  const formatData = (res, options) => {
    // use reverse() to find the last status that fullfil the condition
    const formStatusHistories = res.baseForm?.formStatusHistories?.reverse() || []

    const drsDraftStatusId = globalState.formStatusList?.find(
      (formStatus) => formStatus.actionCode === FormStatusEnum.DailyRecordSummaryDraft,
    )?.id
    // console.debug('drsDraftStatusId', drsDraftStatusId)
    const drsFirstReviewStatusId = globalState.formStatusList?.find(
      (formStatus) => formStatus.actionCode === FormStatusEnum.DailyRecordSummaryFirstReview,
    )?.id
    // console.debug('drsFirstReviewStatusId', drsFirstReviewStatusId)
    const drsFirstApprovedStatusId = globalState.formStatusList?.find(
      (formStatus) => formStatus.actionCode === FormStatusEnum.DailyRecordSummaryFirstApproved,
    )?.id
    const drsSecondReviewStatusId = globalState.formStatusList?.find(
      (formStatus) => formStatus.actionCode === FormStatusEnum.DailyRecordSummarySecondReview,
    )?.id
    // console.debug('drsSecondReviewStatusId', drsSecondReviewStatusId)
    const drsSecondApprovedStatusId = globalState.formStatusList?.find(
      (formStatus) => formStatus.actionCode === FormStatusEnum.DailyRecordSummarySecondApproved,
    )?.id

    const completedPart2 = formStatusHistories.includes(
      ({ formStatusId }) => formStatusId === drsSecondReviewStatusId,
    )
    console.log('completedPart2', completedPart2)

    const designatedDisposalGround = _.chain(options.disposalGround)
      .filter((d) => res.designatedDisposalGround?.includes(d.key))
      .map((d) => d.value)
      .join(', ')
      .value()

    const approvedAlternativeDisposalGround = _.chain(options.disposalGround)
      .filter((d) => res.approvedAlternativeDisposalGround?.includes(d.key))
      .map((d) => {
        if (cleanString(d.value) === cleanString(DisposalGroundEnum.other)) {
          return res.otherApprovedAlternativeDisposalGround || d.value
        } else {
          return d.value
        }
      })
      .join(', ')
      .value()

    const drsItems = _.map(res.drsItems, (drsItem) => {
      const constructionStage = _.get(
        _.find(options.constructionStageList, (item) => item.key === drsItem.constructionStage),
        'value',
        '',
      )

      const cDMaterialType = _.get(
        _.find(options.cDMaterialType, (item) => item.key === drsItem.cDMaterialType),
        'value',
        '',
      )

      const approxVol = _.get(
        _.find(options.approximateValue, (item) => item.key === drsItem.approxVol),
        'value',
        '',
      )

      const disposalGround = _.get(
        _.find(options.disposalGround, (item) => item.key === drsItem.disposalGround),
        'value',
        '',
      )

      const actualDisposalGround = completedPart2
        ? _.get(
            _.find(options.disposalGround, (item) => item.key === drsItem?.actualDisposalGround),
            'value',
            '',
          )
        : ''

      const departureTimeFromSite = drsItem.departureTimeFromSite
        ? moment(drsItem.departureTimeFromSite).format('HH:mm')
        : ''
      console.debug('globalState.formStatusList', globalState.formStatusList)
      const arrivedTime =
        drsItem.arrivedTime && completedPart2 ? moment(drsItem.arrivedTime).format('HH:mm') : ''

      const contractorSignature =
        formStatusHistories.find((history) => history.formStatusId === drsFirstApprovedStatusId)
          ?.actionSignatureBase64 ?? ''

      const reSignature =
        formStatusHistories.find((history) => history.formStatusId === drsSecondApprovedStatusId)
          ?.actionSignatureBase64 ?? ''

      return {
        ...drsItem,
        constructionStage,
        cDMaterialType,
        disposalGround,
        approxVol,
        actualDisposalGround,
        departureTimeFromSite,
        arrivedTime,
        contractorSignature,
        reSignature,
      }
    })

    const submittedByStatus = formStatusHistories.find(
      (history) => history.formStatusId === drsFirstApprovedStatusId,
    )
    console.log('submittedByStatus', submittedByStatus)

    const submittedBySignature = submittedByStatus?.actionSignatureBase64 ?? ''

    const submittedByDate = submittedByStatus?.actionAt
      ? moment(submittedByStatus?.actionAt).format('DD-MM-YYYY')
      : ''
    const submittedByUuid = submittedByStatus?.actionBy ?? ''

    const submittedByName =
      globalState.userMetaList?.find((x) => x.key === submittedByUuid)?.value ?? ''

    const formStatus = res.baseForm?.formStatus
    const approvedByStatus =
      formStatus === FormStatusEnum.DailyRecordSummarySecondApproved
        ? formStatusHistories.find((history) => history.formStatusId === drsSecondReviewStatusId)
        : {}

    console.log('approvedByStatus', approvedByStatus)
    const receivedBySignature = approvedByStatus?.actionSignatureBase64 ?? ''

    const receivedByDate = approvedByStatus?.actionAt
      ? moment(
          formStatusHistories.find((history) => history.formStatusId === drsSecondReviewStatusId)
            ?.actionAt,
        ).format('DD-MM-YYYY HH.mm')
      : ''

    const receivedByUuid = approvedByStatus?.actionBy ?? ''

    const receivedByName =
      globalState.userMetaList?.find((x) => x.key === receivedByUuid)?.value || ''

    return {
      ...res,
      formattedDateOfDisposal: res.dateOfDisposal
        ? moment(res.dateOfDisposal).format('DD-MM-YYYY')
        : '',
      designatedDisposalGround,
      approvedAlternativeDisposalGround,
      drsItems,
      submittedBySignature,
      submittedByDate,
      submittedByName,
      submittedByUuid,
      receivedBySignature,
      receivedByDate,
      receivedByName,
    }
  }

  const exportDocx = async (
    records: any,
    returnFormat: 'blob' | 'blob-download' | 'pdf-blob' | 'pdf-download',
  ) => {
    const exportFn = (records) =>
      DocumentExporter({
        templatePath: '/templates/DRS_Record_Export_YYYYMMDD-YYYYMMDD_Bulk.docx',
        data: records,
        reportName: `DRS_Record_Export_${moment(records?.drsRecords?.[0]?.dateOfDisposal).format(
          'YYYYMMDD',
        )}-${moment(records?.drsRecords?.[records?.drsRecords?.length - 1]?.dateOfDisposal).format(
          'YYYYMMDD',
        )}`,
        returnFormat: returnFormat ?? 'blob-download',
        additionalJsContext: {
          processLineBreaks: false,
          base64ToImage: async (base64) => {
            try {
              let widthHeight = await GetImageDimensions(base64, true)
              let width = 2
              let height = (2 / widthHeight.width) * widthHeight.height

              let formattedImage = {
                width: width ?? 6,
                height: height ?? 6,
                data: base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
                extension: '.jpeg',
              }
              return formattedImage
            } catch (err) {
              // enqueueSnackbar(t('Error in exporting image in pdf.'), { variant: 'error', autoHideDuration: null })
            }
          },
        },
      })
    let data: any = {}
    data.drsRecords = []
    records?.map((record) => {
      let _ = formatData(record, drsOptions)
      _.baseForm.contractName = currentContract?.contractName
      _.drsItems = _.drsItems?.map((item) => {
        const newItem = { ...item }
        Object.keys(newItem).forEach((key) => {
          if (newItem[key] === undefined || newItem[key] === null) {
            newItem[key] = ''
          }
        })
        return newItem
      })
      _.isDemolition = currentContract?.isDemolition ?? false
      data.drsRecords.push(_)
      console.log('data', data)
    })
    exportFn(data)
  }

  // console.debug('[formStatusList]', globalState.formStatusList)

  const [TableView, reload, selected] = useTableView<FormDrsListModel>({
    selectable: true,
    headers: [
      {
        key: 'short_name',
        desc: t('common:baseForm.generalStatus', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'action_name',
        desc: t('common:baseForm.formStatus', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsListModel) => <Fragment>{t(record.formStatus)}</Fragment>,
      },
      {
        key: 'report_no',
        desc: t('common:baseForm.formId', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'date_of_disposal',
        desc: t('forms:dailyRecordSummary.fields.dateOfDisposal', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsListModel) => (
          <Fragment>
            {record.dateOfDisposal ? moment(record.dateOfDisposal).format('DD-MM-yyyy') : 'N/A'}
          </Fragment>
        ),
      },

      {
        key: 'updated_at',
        desc: t('common:baseForm.submittedBy', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsListModel) => (
          <Fragment>
            {GetValueWithKey(record.submittedBy, globalState.userMetaList ?? []) ?? 'N/A'}
          </Fragment>
        ),
      },

      {
        key: 'updated_at',
        desc: t('common:baseForm.approvedBy', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsListModel) => (
          <Fragment>
            {GetValueWithKey(record.approvedBy, globalState.userMetaList ?? []) ?? 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        desc: t('common:baseForm.dateApproved', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsListModel) => (
          <Fragment>
            {record.dateApproved ? moment(record.dateOfArchived).format('DD-MM-yyyy') : 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        desc: t('common:baseForm.updatedAt', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format('DD-MM-yyyy HH:mm') : 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'updated_at',
        width: 200,
        desc: t('common:buttons.actions', { ns: common.i18nFormNS }),
        renderCell: (record: FormDrsListModel) => (
          <Fragment>
            <IconButton
              className="edit-button"
              sx={{
                index: 1000,
                ':hover': {
                  backgroundColor: 'transparent',
                  transition: 'transform 500ms ease-in-out',
                  transform: 'rotate(15deg)',
                },
                // display: handleClickDownload ? 'inherit' : 'none',
              }}
              onClick={() => {
                // handleClickDownload!(record.formId, record.formStatus, record.reportNo, 'pdf')
              }}>
              <RiEdit2Fill />
            </IconButton>
            <IconButton
              disabled={
                record.formStatusId ===
                globalState.formStatusList?.find(
                  (formStatus) => formStatus.actionCode === FormStatusEnum.DailyRecordSummaryDraft,
                )?.id
              }
              className="download-button"
              sx={{
                index: 1000,
                ':hover': {
                  backgroundColor: 'transparent',
                  transition: 'transform 500ms ease-in-out',
                  transform: 'rotate(15deg)',
                },
              }}
              onClick={() => {
                // handleClickDownload!(record.formId, record.formStatus, record.reportNo, 'docx')
              }}>
              <BsFiletypeDocx />
            </IconButton>
            <IconButton
              className="download-pdf-button"
              disabled={
                record.formStatusId ===
                globalState.formStatusList?.find(
                  (formStatus) => formStatus.actionCode === FormStatusEnum.DailyRecordSummaryDraft,
                )?.id
              }
              sx={{
                index: 1000,
                ':hover': {
                  backgroundColor: 'transparent',
                  transition: 'transform 500ms ease-in-out',
                  transform: 'rotate(15deg)',
                },
              }}
              onClick={() => {
                // handleClickDownload!(record.formId, record.formStatus, record.reportNo, 'pdf')
              }}>
              <BsFiletypePdf />
            </IconButton>
          </Fragment>
        ),
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: FormDrsListModel, event: any) {
      if (event.target.matches('.download-button *')) {
        handleClickDownload!(record.formId, record.formStatus, record.reportNo, 'docx')
      } else if (event.target.matches('.download-pdf-button *')) {
        handleClickDownload!(record.formId, record.formStatus, record.reportNo, 'pdf')
      } else if (event.target.matches('.edit-button *')) {
        NavigateTo(navigate, '/daily-record-summary/:id', { id: record.formId })
      }
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (contractId > 0) {
        let res = await FormDrsService.GetDrsList(
          {
            ...state.filterResult,
            pagination: pagination,
            sorting: sorting,
            contractNo: {
              Operator: '=',
              Value: [contractId],
            },
          },

          cancelToken,
          props.showMyRecord || false,
        )
        setExtraListInfo({
          submittedByList: res.submittedByList,
          approvedByList: res.approvedByList,
          formIdList: res.formIdList,
          parentFormIdList: res.parentFormIdList,
        })
        allRecordsList.current = res?.list!
        return res
      } else {
        let res = await FormDrsService.GetDrsList(
          {
            ...state.filterResult,
            pagination: pagination,
            sorting: sorting,
            contractNo: {
              Operator: '=',
              Value: [currentContract?.contractId],
            },
          },

          cancelToken,
          props.showMyRecord || false,
        )
        setExtraListInfo({
          submittedByList: res.submittedByList,
          approvedByList: res.approvedByList,
          formIdList: res.formIdList,
          parentFormIdList: res.parentFormIdList,
        })
        allRecordsList.current = res?.list!
        return res
      }
    },
  })

  useEffect(() => {
    document.title = t('forms:dailyRecordSummary.title', { ns: common.i18nFormNS })
    // eslint-disable-next-line
  }, [])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleExportSelected = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Grid component="main" container padding={3}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <RiFileTextFill style={{ fontSize: 40, color: '#707070' }} />
        <Typography sx={{ color: '#707070' }} variant="h4">
          {props.showMyRecord ? t('My Actions') : t('All Records')}
        </Typography>
        <SearchPanel
          dispatch={dispatch}
          addUrl={
            hasRightByCatAndCode(FormStatusEnum.DailyRecordSummary, ['C'])
              ? '/daily-record-summary'
              : undefined
          }
          onSearch={reload}
          onInitReload={reload}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'reportPeriod',
              desc: t('forms:dailyRecordSummary.fields.dateOfDisposal', { ns: common.i18nFormNS }),
              type: 'DateFilter',
              subType: 'date',
              disableOperator: true,
              defaultOperator: 'in',
              defaultFrom: '',
              defaultTo: '',
            },
            {
              key: 'formStatus',
              desc: t('common:baseForm.formStatus'),
              type: 'StringSelectionFilter',
              valueList:
                globalState.formStatusList
                  ?.filter(
                    (x) =>
                      x.actionCode.includes(RightsCategory.DailyRecordSummary) &&
                      !x.actionCode.includes(RightsCategory.ChitRecord) &&
                      !x.actionCode.includes(RightsCategory.DailyRecordSummaryUploadPlatform),
                  )
                  ?.map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
            },
            {
              key: 'submittedBy',
              desc: t('common:baseForm.submittedBy'),
              type: 'StringSelectionFilter',
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo?.submittedByList?.includes(x.key),
              ),
            },
            {
              key: 'approvedBy',
              desc: t('common:baseForm.approvedBy'),
              type: 'StringSelectionFilter',
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo?.approvedByList?.includes(x.key),
              ),
            },
          ]}
          extraButtons={
            <>
              <IconButton
                onClick={handleExportSelected}
                sx={{
                  margin: '0 5px 0 5px',
                  backgroundColor: globalState.headerColor,
                  color: 'white',
                  borderRadius: '5px',
                  '&:hover': {
                    backgroundColor: globalState.headerColor,
                  },
                  '&:disabled': {
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    backgroundColor: globalState.headerColor,
                  },
                }}
                disabled={selected?.length <= 0}>
                <AddBoxIcon sx={{ color: 'white' }} />
                <Box
                  sx={{
                    fontSize: '16px',
                    paddingLeft: '4px',
                    color: 'white',
                  }}>
                  {t('common:buttons.exportSelected')}
                </Box>
              </IconButton>{' '}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}>
                <Typography sx={{ p: 2 }}>
                  <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical button group"
                    variant="text">
                    <Button
                      sx={{
                        color: '#000000 !important',
                        borderBottomColor: '#000000 !important',
                      }}
                      onClick={() => {
                        handleMultiDownload('docx')
                      }}>
                      {t('common:buttons.exportWord')}
                    </Button>
                    <Button
                      sx={{
                        color: '#000000 !important',
                        borderBottomColor: '#000000 !important',
                      }}
                      onClick={() => {
                        handleMultiDownload('pdf')
                      }}>
                      {t('common:buttons.exportPdf')}
                    </Button>
                  </ButtonGroup>
                </Typography>
              </Popover>
            </>
          }
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default DailyRecordSummaryAllRecords
