import createReport from 'docx-templates'
import ConvertorsService from '@services/convertors.service'
import moment from 'moment'
import { blobToBase64 } from './blobToB64'
const DocumentExporter = async ({
  templatePath,
  reportName,
  data,
  returnFormat,
  additionalJsContext,
}: {
  templatePath: string
  reportName: String
  data: any
  returnFormat?: 'blob' | 'blob-download' | 'pdf-download' | 'pdf-blob'
  additionalJsContext?: Object
}) => {
  const template = await getTemplate(templatePath)
  console.log(templatePath,'templatePath');
  console.log(template,'template');
  try {
    const report = await createReport({
      // @ts-ignore
      template,
      data: data,
      additionalJsContext: additionalJsContext,
    })
    switch (returnFormat) {
      case 'blob':
        {
          saveDataToFile(
            report,
            reportName + '.docx',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'blob',
          )
        }
        break
      case 'blob-download':
        {
          saveDataToFile(
            report,
            reportName + '.docx',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'blob-download',
          )
        }
        break
      case 'pdf-blob':
        {
          saveDataToFile(report, reportName + '.pdf', 'application/pdf', 'pdf-blob')
        }
        break
      case 'pdf-download':
        {
          saveDataToFile(report, reportName + '.pdf', 'application/pdf', 'pdf-download')
        }
        break
    }
  } catch (err) {
    console.error(err)
    return err
  }
}

async function getTemplate(dataPath): Promise<Blob> {
  const request = await fetch(dataPath)
  const defaultTemplate = await request.blob()
  return defaultTemplate
}

export const saveDataToFile = async (
  data: any,
  fileName: string,
  mimeType: string,
  returnFormat?: 'blob' | 'blob-download' | 'pdf-download' | 'pdf-blob',
) => {
  const blob = new Blob([data], { type: mimeType })
  let url = window.URL.createObjectURL(blob)

  switch (returnFormat) {
    case 'blob':
    case 'blob-download':
      if (returnFormat === 'blob') {
        let result = await blobToBase64(url).then((base64data) => {
          return base64data
        })
        return result
      } else {
        downloadURL(url, fileName)
        setTimeout(() => {
          window.URL.revokeObjectURL(url)
        }, 1000)
      }

      break
    case 'pdf-blob':
    case 'pdf-download':
      const formData = new FormData()
      formData.append('file', blob)
      const pdfBlob = await ConvertorsService.ConvertFiles(formData)
      url = window.URL.createObjectURL(pdfBlob)
      if (returnFormat === 'pdf-blob') return url
      downloadURL(url, fileName)
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
      }, 1000)
      break
  }
}

const downloadURL = (data, fileName) => {
  const a = document.createElement('a')
  a.href = data
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export { DocumentExporter }
