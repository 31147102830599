import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import FormMultipleSelect from '@components/form/multipleSelect'
import { QueryStepParser } from '@utils/queryStepParser'
import FormSelect from '@components/form/select'
import FormController from '@components/form/controller'
import { EnrolmentStatusEnum, RoleNameEnum } from '@services/model/admin.model'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import { ContractFormModel, InitContractForm } from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { useNavigate, useParams } from 'react-router-dom'
import FormFreeText from '@components/form/freeText'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { DevTool } from '@hookform/devtools'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import FormBinarySelect from '@components/form/binarySelect'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import styles from '@styles/baseButton.module.scss'

import {
  Box,
  Typography,
  Divider,
  Button,
  Grid,
  useMediaQuery,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from '@mui/material'

import SnackbarCloseButton from '@components/SnackbarCloseButton'
import {
  GridCellParams,
  GridColDef,
  GridFilterOperator,
  GridRenderCellParams,
} from '@mui/x-data-grid'
import FormDataGrid from '@components/form/datagrid'
import { RightsCategory } from '@services/model/form/form.model'
import BaseButton from '@components/button/BaseButton'
import Tabs from '@components/Tabs'
import { ApiStatusEnum } from '@services/model/base.model'
import { RolesEnum } from '@services/model/user.model'
import getErrMsg from '@utils/getErrMsg'
import cleanString from '@utils/cleanString'
import { maxHeight } from '@mui/system'

export type DialogContextProps = {
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

const AdminContractFormPage = () => {
  const UUID = uuid()
  const [readonly, setReadonly] = useState<boolean>(false)
  const { state: globalState, userInfo, dispatch } = useContext(GlobalContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const theme = useTheme()
  let { id: formId } = useParams<string>()
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContractFormModel>({
    defaultValues: { ...InitContractForm },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setRequestUsers } = useAPIFetch()

  const rows: any = []
  const [usersData, setUsersData] = useState<any>(rows)

  const [userDetailList, setUserDetailList] = useState<any>([])
  const [activeUsersData, setActiveUsersData] = useState<any>(rows)
  const [inactiveUsersData, setInactiveUsersData] = useState<any>(rows)
  const [pendingInvitationUsersData, setPendingInvitationUsersData] = useState<any>(rows)
  const [pendingApprovalUsersData, setPendingApprovalUsersData] = useState<any>(rows)
  const [activeProjectsData, setActiveProjectsData] = useState<any>(rows)
  const [inactiveProjectsData, setInactiveProjectsData] = useState<any>(rows)
  const [activeOrganizationsData, setActiveOrganizationsData] = useState<any>(rows)
  const [inactiveOrganizationsData, setInactiveOrganizationsData] = useState<any>(rows)
  const [organizationDetailList, setOrganizationDetailList] = useState<any>([])
  const [organizationsData, setOrganizationsData] = useState<any>(rows)
  const [addUserOrganizationId] = useWatch({
    name: ['addUserOrganizationId'],
    control: control,
  })

  const initAddUserIdOptionList =
    userDetailList
      ?.filter(
        (x) =>
          x.roleId?.some((role) => role === RolesEnum.EO || role === RolesEnum.EM) &&
          x.status === true,
      )
      ?.filter((y) => !usersData.some((user) => user.userId === y.userId)) // filter out users already in project
      .map((u) => {
        return { key: u.userId, value: u.displayName }
      }) || []

  const [addUserIdOptionList, setAddUserIdOptionList] = useState<any>(initAddUserIdOptionList)

  useEffect(() => {
    const filteredList =
      userDetailList
        ?.filter((user) => user.organizationId === addUserOrganizationId || user.organization2Id === addUserOrganizationId)
        ?.filter(
          (x) =>
            x.roleId?.some((role) => role === RolesEnum.EO || role === RolesEnum.EM) &&
            x.status === true,
        )
        // ?.filter((y) => !usersData.some((user) => user.userId === y.userId)) // filter out users already in project
        .map((u) => {
          return { key: u.userId, value: u.displayName }
        }) || []

    setAddUserIdOptionList(filteredList)
  }, [addUserOrganizationId])

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await AdminService.GetContractForm(formId)
            .then(async (res) => {
              if (res) {
                reset(res)
                setReadonly(res.contractPermission?.canUpdate === false)
                const projects = res.projects ?? []

                setActiveProjectsData(projects.filter((project) => project.status === true))
                setInactiveProjectsData(projects.filter((project) => project.status === false))
                const assignedOrganizationList = res.assignedOrganizationList ?? []
                const organizations = organizationDetailList.filter((o) =>
                  assignedOrganizationList.includes(o.uuid),
                )
                setOrganizationsData(organizationDetailList.filter((x) => x.status === true))
                setActiveOrganizationsData(organizations.filter((x) => x.status === true))
                setInactiveOrganizationsData(organizations.filter((x) => x.status === false))
              }
            })
            .catch((err) => {
              enqueueSnackbar(getErrMsg(t, err), {
                variant: 'error',
              })
            })
        }
        isMounted.current = true
      },
    })

    const reqBody = {
      skipPagination: true,
      uuid: {
        operator: '=',
        value: '',
      },
    }

    setRequestUsers({
      callback: async () => {
        if (formId) {
          await AdminService.GetContractUserList(formId).then((userList) => {
            if (userList && userDetailList) {
              const users = userDetailList.filter((userDetail) =>
                userList.includes(userDetail.userId),
              )
              setUsersData(users)
              console.debug('[users]', users)

              setActiveUsersData(users)

              setInactiveUsersData(
                users.filter(
                  (x) =>
                    x.enrolment ===
                      globalState?.enrolmentStatusList?.find(
                        (enrolStatus) =>
                          cleanString(enrolStatus.value) ===
                          cleanString(EnrolmentStatusEnum.disabled)?.key,
                      ) || x.status === false,
                ),
              )
              setPendingInvitationUsersData(
                users.filter(
                  (x) =>
                    x.enrolment ===
                    globalState?.enrolmentStatusList?.find(
                      (enrolStatus) =>
                        cleanString(enrolStatus.value) ===
                        cleanString(EnrolmentStatusEnum.pendingInvitation)?.key,
                    ),
                ),
              )
              setPendingApprovalUsersData(
                users.filter(
                  (x) =>
                    x.enrolment ===
                    globalState?.enrolmentStatusList?.find(
                      (enrolStatus) =>
                        cleanString(enrolStatus.value) ===
                        cleanString(EnrolmentStatusEnum.pendingApproval)?.key,
                    ),
                ),
              )
              // setActiveUsersData(users.filter((x) => x.enrolment === EnrolmentStatusEnum.ACTIVE))
              // setInactiveUsersData(
              //   users.filter((x) => x.enrolment === EnrolmentStatusEnum.DISABLED),
              // )
              // setPendingInvitationUsersData(
              //   users.filter((x) => x.enrolment === EnrolmentStatusEnum.PENDING_INVITATION),
              // )
              // setPendingApprovalUsersData(
              //   users.filter((x) => x.enrolment === EnrolmentStatusEnum.PENDING_APPROVAL),
              // )
            }
          })
        }
      },
    })
  }

  const handleAddContractUser = async (event: any) => {
    console.debug('handleAddContractUser')
    const { addUserId } = getValues()
    const req = { users: [addUserId] } // api accept array
    if (formId) {
      await AdminService.AddContractUser(formId, req)
        .then((res) => {
          if (res) {
            reload()
          }
        })
        .catch((err) => {
          enqueueSnackbar(getErrMsg(t, err), {
            variant: 'error',
          })
        })
    }
  }
  const handleRemoveContractUser = async (userId: any) => {
    const req = { users: [userId] } // api accept array
    if (formId) {
      // const res = await AdminService.RemoveContractUser(formId, req)
      await AdminService.RemoveContractUser(formId, req)
        .then((res) => {
          if (res.status === ApiStatusEnum.SUCCESS) {
            reload()
            reloadUserDetailList()
          }
        })
        .catch((err) => {
          enqueueSnackbar(getErrMsg(t, err), {
            variant: 'error',
          })
        })
    }
  }
  useEffect(() => {
    if (formId == null) {
      reset({ ...InitContractForm })
    }
    reloadUserDetailList()
    reloadOrganizationDetailList()
  }, [formId])

  useEffect(() => {
    reload()
  }, [userDetailList, organizationDetailList])

  const reloadUserDetailList = async () => {
    let res = await AdminService.GetUserList({ skipPagination: true }, null, false)
    if (res?.list) {
      setUserDetailList(res.list)
    }
  }

  const reloadOrganizationDetailList = async () => {
    let res = await AdminService.GetOrganizationList({ skipPagination: true }, null, false)
    if (res?.list) {
      setOrganizationDetailList(res.list)
    }
  }

  const handleOnSubmit = async (event: any) => {
    let values = getValues()
    if (values.status === undefined) {
      values.status = true
    }
    setRequest({
      callback: async () => {
        const contractNo = values.contractNo ?? '';
        const contractExists = await AdminService.CheckContractNoExists(contractNo);

        if (contractExists) {
          const userConfirmed = window.confirm(t('Contract number already exists. Do you want to proceed?'));
          if (!userConfirmed) {
            return;
          }
        }else{
          const userConfirmed = window.confirm(t('Confirm to proceed?'));
          if (!userConfirmed) {
            return;
          }
        }

        await AdminService.SaveCreateContract(values).then((resp) => {
          enqueueSnackbar(t('common:messages.recordSaved'), {
            variant: 'success',
            action: (key) => {
              return <SnackbarCloseButton id={key} />
            },
          })
          NavigateTo(navigate, '/admin/contract/all-records')
        })
      },
    })
  }

  let index = 0
  let title = 'Add New Contract' //TODO: form switcher remove A/ if only one part
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'block',
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  }))

  const StyledDivider = styled(Divider)(() => ({
    '&::before, &::after': {
      borderColor: 'black',
    },
  }))

  const ButtonGroupBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    pt: 2,
  }))

  const SwitcherButton = styled(Button)(() => ({
    color: '#000000',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  }))

  const handleBack = async (event: any) => {
    NavigateTo(navigate, '/admin/contract/all-records')
  }

  const handleInviteUser = async (event: any) => {
    const { inviteUser: cicUserProfile } = getValues()
    const { roles, contractId, projectId, organizationId, ...ciamUserProfile } = cicUserProfile!
    if (cicUserProfile) {
      if (formId) {
        cicUserProfile.contractId = parseInt(formId)
      }
      const res = await AdminService.InviteUser(cicUserProfile)
        .then(async (res) => {
          if (res) {
            await handeCiamInviteUser(ciamUserProfile, res.userId)
            reloadUserDetailList()
          }
        })
        .catch(async (err) => {
          if (err.response?.data?.code === 450) {
            await handeCiamInviteUser(ciamUserProfile, err.response?.data?.userId)
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          } else {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          }
        })
    }
  }

  const handeCiamInviteUser = async (ciamUserProfile: any, userId: string) => {
    await AdminService.CiamGetUserProfile(ciamUserProfile?.email ?? '')
      .then(async (res) => {
        if (res?.id) {
          await AdminService.CiamAddUserToGroup(res.id).catch(async (err) => {
            if (err && !err.response) {
              await handleEditCiamInfo(userId, res.id)
                .then(async (err) => {
                  enqueueSnackbar(t('Successfully add Ciam User Id to the Database'), {
                    variant: 'success',
                  })
                })
                .catch(async (err) => {
                  enqueueSnackbar(t('Fail to add Ciam User Id to the Database'), {
                    variant: 'error',
                  })
                })
              enqueueSnackbar(t('Successfully Add Okta User in General Group'), {
                variant: 'success',
              })
            }
          })
        } else {
          console.log('no res.id but success handeCiamInviteUser')
          ciamUserProfile.login = ciamUserProfile.email
          ciamUserProfile.displayName = ciamUserProfile.firstName + ' ' + ciamUserProfile.lastName
          await AdminService.CiamCreateNewUser({ profile: ciamUserProfile })
            .then(async (res) => {
              if (res) {
                const ciamUserId = res.id
                await AdminService.CiamAddUserToGroup(ciamUserId).catch(async (err) => {
                  if (err && !err.response) {
                    await handleEditCiamInfo(userId, ciamUserId)
                      .then(async (err) => {
                        enqueueSnackbar(t('Successfully Add Ciam User Id to the Database'), {
                          variant: 'success',
                        })
                      })
                      .catch(async (err) => {
                        enqueueSnackbar(t('Fail to add Ciam User Id to the Database'), {
                          variant: 'error',
                        })
                      })
                    enqueueSnackbar(t('Successfully Add Okta User in General Group'), {
                      variant: 'success',
                    })
                  }
                })
              }
            })
            .catch((err) => {
              if (err.response?.data) {
                enqueueSnackbar(
                  err.response?.data?.errorSummary ?? 'Unknown Okta Error Creating User',
                  {
                    variant: 'error',
                  },
                )
              }
            })
        }
      })
      .catch(async (err) => {
        console.log('in catch', err)

        // no user found, create user
        ciamUserProfile.login = ciamUserProfile.email
        ciamUserProfile.displayName = ciamUserProfile.firstName + ' ' + ciamUserProfile.lastName
        await AdminService.CiamCreateNewUser({ profile: ciamUserProfile })
          .then(async (res) => {
            if (res) {
              const ciamUserId = res.id
              await AdminService.CiamAddUserToGroup(ciamUserId).catch(async (err) => {
                if (err && !err.response) {
                  await handleEditCiamInfo(userId, ciamUserId)
                    .then(async (err) => {
                      enqueueSnackbar(t('Successfully Add Ciam User Id to the Database'), {
                        variant: 'success',
                      })
                    })
                    .catch(async (err) => {
                      enqueueSnackbar(t('Fail to add Ciam User Id to the Database'), {
                        variant: 'error',
                      })
                    })
                  enqueueSnackbar(t('Successfully Add Okta User in General Group'), {
                    variant: 'success',
                  })
                }
              })
            }
          })
          .catch((err) => {
            if (err.response?.data) {
              enqueueSnackbar(
                err.response?.data?.errorSummary ?? t('Unknown Okta Error Creating User'),
                {
                  variant: 'error',
                },
              )
            }
          })
      })
  }

  const handleEditCiamInfo = async (userId: string, email: string) => {
    await AdminService.EditUserCiamInfo(userId, email)
      .then((res) => {
        enqueueSnackbar('Successfully update Ciam User Id', {
          variant: 'success',
        })
      })
      .catch((err) => {
        enqueueSnackbar(getErrMsg(t, err), {
          variant: 'error',
        })
      })
  }

  const findTreeNode = (key: number, tree: any[]): any => {
    for (let i = 0; i < tree.length; i++) {
      const [addUserOrganizationId] = useWatch({
        name: ['addUserOrganizationId'],
        control: control,
      })

      const node = tree[i]
      if (node.key === key) {
        return node
      } else if (node.children) {
        const childNode = findTreeNode(key, node.children)
        if (childNode) {
          return childNode
        }
      }
    }
    return null
  }

  const getRoleName = (key: number, treeData): string => {
    const node = findTreeNode(key, treeData)
    return node ? node.value : ''
  }

  const ArrayRenderer = (params: GridCellParams) => {
    const arrayValue = params.value as number[]
    const roleNames = arrayValue.map((value) => `${getRoleName(value, globalState.roleMetaList)}`)
    const roleNameString = roleNames.join(', ')
    return <div>{roleNameString}</div>
  }

  const RoleDropdownOperator: GridFilterOperator = {
    label: 'Role Name',
    value: 'role-dropdown',
    getApplyFilterFn: (filterItem) => {
      const roleId = filterItem.value as number

      return (params) => {
        const roleIds = params.value as number[]
        return roleId === 0 || !roleId || roleIds.includes(roleId)
      }
    },
    InputComponent: ({ item, applyValue, ...rest }) => {
      const [selectedRoleId, setSelectedRoleId] = useState<number>(0)

      const handleRoleSelect = (event) => {
        const roleId = event.target.value
        setSelectedRoleId(roleId)
        applyValue({ ...item, value: roleId })
      }
      return (
        <FormControl sx={{ minWidth: 120 }} variant={'standard'}>
          <InputLabel id="role-select-label">Role Name</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={selectedRoleId}
            onChange={handleRoleSelect}
            {...rest}>
            <MenuItem value={0}>{t('Select')}</MenuItem>
            {globalState.roleMetaList
              ?.filter(
                (x) =>
                  x.value === RoleNameEnum.EnvironmentalOfficer ||
                  x.value === RoleNameEnum.EnvironmentalManager,
              )
              ?.map((node) => (
                <MenuItem key={node.key} value={node.key}>
                  {t(node.value as string)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    },
  }

  type ListComponentProps = {
    status: 'active' | 'inactive' | 'invited'
    dataType: 'User' | 'Project' | 'Organization'
    control: any
    readonly: boolean
    data: any[]
    columns: GridColDef[]
    rowId: string
  }

  const ListComponent = (props: ListComponentProps) => {
    const { status, control, readonly, data, dataType, columns, rowId } = props

    const controlProps = {
      name: `${status}${dataType}List`,
      control,
    }

    return (
      <FormController controllerProps={controlProps}>
        <FormDataGrid
          getRowId={(row: any) => row[rowId]}
          rows={data}
          columns={columns}
          // disableSelectionOnClick
          // experimentalFeatures={{ newEditingApi: true }}
          uneditable={readonly}
          checkboxSelection={false}
          initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        />
      </FormController>
    )
  }

  const userColumns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: t('Display Name'),
      width: 150,
      editable: false,
    },
    {
      field: 'roleId',
      headerName: t('Roles'),
      width: 110,
      editable: false,
      renderCell: ArrayRenderer,
      filterOperators: [RoleDropdownOperator],
    },
    {
      field: 'userId',
      headerName: t('Link to Edit User'),

      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            onClick={() => {
              navigate(`/admin/user/${params.value}`)
            }}
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            {t('Link to User')}
          </Button>
        </strong>
      ),
      width: 300,
    },
    {
      field: 'removeUser',
      headerName: t('Remove User'),
      renderCell: (params: GridRenderCellParams) => (
        <Button
          onClick={() => {
            handleRemoveContractUser(params.row.userId)
          }}
          variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          tabIndex={params.hasFocus ? 0 : -1}>
          {t('Remove User from Contract')}
        </Button>
      ),
      width: 300,
    },
  ]

  const organizationColumns: GridColDef[] = [
    {
      field: 'companyName',
      headerName: t('Company Name'),
      width: 150,
      editable: false,
    },
    {
      field: 'subsidiaryName',
      headerName: t('Subsidiary Name'),
      width: 150,
      editable: false,
    },
    {
      field: 'uuid',
      headerName: t('Link to Edit Organization'),
      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            onClick={() => {
              navigate(`/admin/organization/${params.value}`)
            }}
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            {t('Link to Edit Organization')}
          </Button>
        </strong>
      ),
      width: 300,
    },
  ]

  const projectColumns: GridColDef[] = [
    {
      field: 'projectName',
      headerName: t('Project Name'),
      width: 150,
      editable: false,
    },

    {
      field: 'id',
      headerName: t('Link to Edit Project'),
      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            onClick={() => {
              navigate(`/admin/project/${params.value}`)
            }}
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            {t('Link to Edit Project')}
          </Button>
        </strong>
      ),
      width: 300,
    },
  ]

  const activeUserList = (
    <ListComponent
      status="active"
      dataType="User"
      control={control}
      readonly={readonly}
      data={activeUsersData}
      columns={userColumns}
      rowId={'userId'}
    />
  )

  const inactiveUserList = (
    <ListComponent
      status="inactive"
      dataType="User"
      control={control}
      readonly={readonly}
      data={inactiveUsersData}
      columns={userColumns}
      rowId={'userId'}
    />
  )

  const pendingInvitationUserList = (
    <ListComponent
      status="invited"
      dataType="User"
      control={control}
      readonly={readonly}
      data={pendingInvitationUsersData}
      columns={userColumns}
      rowId={'userId'}
    />
  )
  const pendingApprovalUserList = (
    <ListComponent
      status="invited"
      dataType="User"
      control={control}
      readonly={readonly}
      data={pendingApprovalUsersData}
      columns={userColumns}
      rowId={'userId'}
    />
  )

  const activeProjectList = (
    <ListComponent
      status="active"
      dataType="Project"
      control={control}
      readonly={readonly}
      data={activeProjectsData}
      columns={projectColumns}
      rowId={'id'}
    />
  )

  const inactiveProjectList = (
    <ListComponent
      status="inactive"
      dataType="Project"
      control={control}
      readonly={readonly}
      data={inactiveProjectsData}
      columns={projectColumns}
      rowId={'id'}
    />
  )

  const activeOrganizationList = (
    <ListComponent
      status="active"
      dataType="Organization"
      control={control}
      readonly={readonly}
      data={activeOrganizationsData}
      columns={organizationColumns}
      rowId={'uuid'}
    />
  )

  const inactiveOrganizationList = (
    <ListComponent
      status="inactive"
      dataType="Organization"
      control={control}
      readonly={readonly}
      data={inactiveOrganizationsData}
      columns={organizationColumns}
      rowId={'uuid'}
    />
  )

  useEffect(() => {
    console.debug('[activeUsersData]', activeUsersData)
  }, [activeUsersData])
  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
        }}>
        <StyledBox>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              variant={matchDownMd ? 'h6' : 'h4'}
              sx={{ fontWeight: 'bold', margin: theme.spacing(5) }}>
              {!formId ? t('Add New Contract') : t('Edit Contract')}
            </Typography>
          </Box>
          <Typography
            variant={matchDownMd ? 'h6' : 'h5'}
            sx={{
              fontWeight: 'bold',
              marginBottom: theme.spacing(4),
              marginLeft: theme.spacing(6),
              marginRight: theme.spacing(6),
            }}>
            {t('Part A / Contract Information')}
          </Typography>
          <FormContainer fkey={`${UUID}-ei-a`}>
            <FormField fieldName="Contract name" index={index++}>
              <FormController
                controllerProps={{
                  name: 'contractName',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Contract no." index={index++}>
              <FormController
                controllerProps={{
                  name: 'contractNo',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Name of contractor awarded the contract" index={index++}>
              <FormController
                controllerProps={{
                  name: 'contractor',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Public Owner/Developer" index={index++}>
              <FormController
                controllerProps={{
                  name: 'publicOwner',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Contract value (million HKD)" index={index++}>
              <FormController
                controllerProps={{
                  name: 'contractValue',
                  control,
                }}>
                <FormFreeText
                  textfieldProps={{ fullWidth: true }}
                  uneditable={readonly}
                  inputType={'float'}
                />
              </FormController>
            </FormField>
            {!!formId && (
              <>
                <FormField fieldName="Assigned Organizations" index={index++}>
                  <Box sx={{ height: 540, width: '100%' }}>
                    {
                      <Tabs
                        data={[
                          {
                            content: activeOrganizationList,
                            menuTitle: t('Active'),
                            title: 'Active',
                          },
                          {
                            content: inactiveOrganizationList,
                            menuTitle: t('Inactive'),
                            title: 'Inactive',
                          },
                        ]}
                      />
                    }
                  </Box>
                </FormField>
                <FormField fieldName="Assigned Projects" index={index++}>
                  <Box sx={{ height: 540, width: '100%' }}>
                    {
                      <Tabs
                        data={[
                          { content: activeProjectList, menuTitle: t('Active'), title: 'Active' },
                          {
                            content: inactiveProjectList,
                            menuTitle: t('Inactive'),
                            title: 'Inactive',
                          },
                        ]}
                      />
                    }
                  </Box>
                </FormField>
                <FormField fieldName="Assigned Users" index={index++}>
                  <Box sx={{ height: 400, width: '100%' }}>
                    {activeUserList}
                    {/* {
                      <Tabs
                        data={[
                          { content: activeUserList, menuTitle: 'Active', title: 'Active' },
                          { content: inactiveUserList, menuTitle: 'Inactive', title: 'Inactive' },
                          {
                            content: pendingInvitationUserList,
                            menuTitle: 'Pending Invitation',
                            title: 'Pending Invitation',
                          },
                          {
                            content: pendingApprovalUserList,
                            menuTitle: 'Pending Approval',
                            title: 'Pending Approval',
                          },
                        ]}
                      />
                    } */}
                  </Box>
                </FormField>
              </>
            )}
          </FormContainer>
          <FormContainer fkey={`${UUID}-admin-d`}>
            {formId ? (
              <>
                <Typography
                  variant={matchDownMd ? 'h6' : 'h5'}
                  sx={{
                    fontWeight: 'bold',
                    marginBottom: theme.spacing(4),
                  }}>
                  {t('Contract Status')}
                </Typography>
                <FormField fieldName="Change Contract Status" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'status',
                      control,
                    }}>
                    <FormBinarySelect
                      binaryOptionNames={{ yes: t('Active'), no: t('Disabled') }}
                      uneditable={readonly}
                    />
                  </FormController>
                </FormField>
              </>
            ) : (
              <></>
            )}
          </FormContainer>
          {!!formId && (
            <Box sx={{ minHeight: '500px' }}>
              <FormContainer fkey={`${UUID}-admin-organ-c`}>
                <Typography
                  variant={matchDownMd ? 'h6' : 'h5'}
                  sx={{
                    fontWeight: 'bold',
                    marginBottom: theme.spacing(4),
                  }}>
                  {t('Add User to Contract')}
                </Typography>
                <FormField fieldName="Organization" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'addUserOrganizationId',
                      control,
                    }}>
                    <FormSelect
                      sx={{ '& .MuiAutocomplete-listbox': { maxHeight: '1vh' } }}
                      options={
                        globalState?.organizationMetaList?.map((x) => {
                          return {
                            key: x.identifier,
                            value: `${x.companyName} ${x.subsidiaryName ?? ''}`,
                          }
                        }) || []
                      }
                    />
                  </FormController>
                </FormField>
                <FormField fieldName="Display Name" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'addUserId',
                      control,
                    }}>
                    <FormSelect options={addUserIdOptionList || []} />
                  </FormController>
                </FormField>
                <FormField fieldName="" index={index++} removeColon>
                  <BaseButton
                    displayText="Add User to Contract"
                    disabled={false}
                    className={styles.formButton}
                    sx={{ width: '250px', marginLeft: '10px' }}
                    onClickHandler={handleAddContractUser}
                  />
                </FormField>
              </FormContainer>
            </Box>
          )}
          {/* {!!formId && userInfo?.canInviteUser && (
            <FormContainer fkey={`${UUID}-contract-invite-user`}>
              <Typography
                variant={matchDownMd ? 'h6' : 'h5'}
                sx={{
                  fontWeight: 'bold',
                  marginBottom: theme.spacing(4),
                }}>
                {t('Invite User')}
              </Typography>
              <FormField fieldName="Email" index={index++}>
                <FormController
                  controllerProps={{
                    name: 'inviteUser.email',
                    control,
                  }}>
                  <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
                </FormController>
              </FormField>
              <FormField fieldName="First Name" index={index++}>
                <FormController
                  controllerProps={{
                    name: 'inviteUser.firstName',
                    control,
                  }}>
                  <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
                </FormController>
              </FormField>
              <FormField fieldName="Last Name" index={index++}>
                <FormController
                  controllerProps={{
                    name: 'inviteUser.lastName',
                    control,
                  }}>
                  <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
                </FormController>
              </FormField>
              <FormField fieldName="Role" index={index++}>
                <FormController
                  controllerProps={{
                    name: 'inviteUser.roles',
                    control,
                  }}>
                  <FormMultipleSelect
                    options={
                      globalState.roleMetaList?.filter((role) =>
                        [RolesEnum.EO, RolesEnum.EM].includes(role.key),
                      ) || []
                    }
                    uneditable={readonly}
                  />
                </FormController>
              </FormField>
              <FormField fieldName="Organization" index={index++}>
                <FormController
                  controllerProps={{
                    name: 'inviteUser.organizationId',
                    control,
                  }}>
                  <FormSelect
                    options={
                      organizationsData.map((x) => {
                        return {
                          key: x.uuid,
                          value: x.companyName,
                        }
                      }) || []
                    }
                    uneditable={readonly}
                  />
                </FormController>
              </FormField>
              <FormField fieldName="" index={index++} removeColon>
                <BaseButton
                  displayText="Invite"
                  
                  disabled={false}
                  className={styles.formButton}
                  sx={{ width: '200px', marginLeft: '10px' }}
                  onClickHandler={handleInviteUser}
                />
              </FormField>
            </FormContainer>
          )} */}
          <Grid>
            <StyledDivider>{t('')}</StyledDivider>
            <ButtonGroupBox>
              <SwitcherButton
                disabled={false}
                onClick={handleBack}
                startIcon={<ArrowBackIosNewIcon />}>
                {!formId ? t('common:buttons.back') : t('common:buttons.back')}
              </SwitcherButton>
              <Box sx={{ flex: '1 1 auto' }} />
              <SwitcherButton
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleOnSubmit}
                disabled={false}>
                {!formId ? t('Submit') : t('Save')}
              </SwitcherButton>
            </ButtonGroupBox>
          </Grid>
          <DevTool control={control} />
        </StyledBox>
      </Box>
    </Fragment>
  )
}
export default AdminContractFormPage
